
























































































































import Vue from "vue";
import { mapState } from "vuex";
import { Toast, Dialog } from "vant";
import { dispatch, ListQuery, Cart, CartProduct, Address } from "@/store";

export default Vue.extend({
  data() {
    return {
      loading: false,
      addressLoading: true,
      selected: [] as number[],
      address: {} as Address,
      showVerify: false,
    };
  },
  computed: {
    ...mapState({ settings: "settings" }),
    ...mapState("cart", {
      list: "list",
      query: "query",
      count: "count",
    }),
    ...mapState("addresses", {
      addressList: "list",
      addressQuery: "query",
    }),
    isCoinPay(): boolean {
      if (this.selected[0]) {
        const cartItem: Cart = this.list.find(
          (item: Cart) => this.selected[0] === item.sku.id
        );
        return cartItem.product.is_coin_pay;
      }
      return false;
    },
    total(): number {
      let total = 0;
      this.list.map((item: Cart) => {
        if (this.selected.includes(item.sku.id)) {
          total +=
            item.number *
            (item.product.is_coin_pay ? item.sku.coin : item.sku.amount);
        }
      });
      return total;
    },
    totalNumber(): number {
      let number = 0;
      this.list.map((item: Cart) => {
        if (this.selected.includes(item.sku.id)) {
          number += item.number;
        }
      });
      return number;
    },
    isShippingOpen(): boolean {
      return this.settings.shipping.is_open;
    },
    shippingThreshold(): number {
      return this.settings.shipping.threshold;
    },
    totalEntity(): number {
      let total = 0;
      this.list.map((item: Cart) => {
        if (this.selected.includes(item.sku.id) && !item.product.is_virtual) {
          total +=
            item.number *
            (item.product.is_coin_pay ? item.sku.coin : item.sku.amount);
        }
      });
      return total;
    },
    shipping(): number {
      const { is_open, amount, threshold } = this.settings.shipping;
      if (
        is_open &&
        amount &&
        this.totalEntity &&
        this.totalEntity < threshold
      ) {
        return amount;
      } else if (!is_open && this.totalEntity && this.totalEntity < 100) {
        return 10;
      } else {
        return 0;
      }
    },
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .cartGetList(query)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onRefresh() {
      this.getList({
        ...this.query,
        page_number: 1,
      });
    },
    getMedia(product: Cart) {
      if (product.sku.medias && product.sku.medias[0]) {
        return product.sku.medias[0];
      } else {
        return product.product.main_medias[0];
      }
    },
    selectAddress() {
      this.$router.push({
        path: this.$paths.addresses,
        query: {
          redirect: this.$route.fullPath,
          address: String(this.address.id),
        },
      });
    },
    selectable(item: Cart) {
      if (item.sku && !item.sku.has_stock && item.sku.is_overdue) {
        return false;
      }
      // if (
      //   item.product &&
      //   item.product.stage === "pre_sell" &&
      //   moment(item.product.sale_at).isAfter()
      // ) {
      //   return false;
      // }
      return true;
    },
    onSelectAll() {
      const list = this.list.filter((item: Cart) => this.selectable(item));
      if (this.selected.length >= list.length) {
        this.selected = [];
      } else {
        const item1 = list.find((item: Cart) => item.product.is_coin_pay);
        const item2 = list.find((item: Cart) => !item.product.is_coin_pay);
        if (item1 && item2) {
          Toast("普通商品与 MIAOCOIN 商品不能合并下单，请分开结算");
          return;
        }
        this.selected = list.map((item: Cart) => item.sku.id);
      }
    },
    onSelect(item: Cart) {
      const selected = [...this.selected];
      const index = selected.findIndex((id) => item.sku.id === id);
      if (index > -1) {
        selected.splice(index, 1);
      } else if (this.selectable(item)) {
        if (selected[0]) {
          const cartItem: Cart = this.list.find(
            (item: Cart) => selected[0] === item.sku.id
          );
          if (cartItem.product.is_coin_pay !== item.product.is_coin_pay) {
            Toast("普通商品与 MIAOCOIN 商品不能合并下单，请分开结算");
            return;
          }
        }
        selected.push(item.sku.id);
      }
      this.selected = selected;
    },
    onDelete(item: Cart) {
      Dialog.confirm({
        title: "提示",
        message: "确认移除该商品？",
      }).then(() => {
        dispatch
          .cartDelete([
            {
              number: item.number,
              product_id: item.product.id,
              sku_id: item.sku.id,
            },
          ])
          .then(() => {
            if (this.selected.includes(item.sku.id)) {
              this.onSelect(item);
            }
            this.onRefresh();
          });
      });
    },
    onNumberChange(number: number, item: Cart) {
      if (number < 1 || number > item.sku.limit) {
        return;
      }
      item.number = number;
      const list = Array.from(this.list, (item: Cart) => ({
        number: item.number,
        product_id: item.product.id,
        sku_id: item.sku.id,
      })).filter((item: CartProduct) => item.number > 0);
      dispatch.cartPatch(list).then(this.onRefresh);
    },
    onSubmit() {
      if (!this.address.id) {
        Toast("请先选择收货地址");
      } else if (!this.selected.length) {
        Toast("请选择需要购买的商品");
      } else {
        const products: CartProduct[] = [];
        this.list.map((item: Cart) => {
          if (this.selected.includes(item.sku.id)) {
            products.push({
              number: item.number,
              product_id: item.product.id,
              sku_id: item.sku.id,
            });
          }
        });
        dispatch.cartCheck(products).then((res) => {
          const error = res.find((item: any) => {
            const product = this.list.find(
              (product: Cart) => product.sku.id === item.sku_id
            );
            if (product) {
              const productName = product.product.name;
              const skuName = product.sku.name;
              if (
                item.product_booked_info &&
                item.product_booked_info[0].is_exceed
              ) {
                Dialog.alert({
                  title: "暂无库存",
                  message: `商品“${productName}”没有足够的库存。`,
                });
                this.onSelect(product);
                return true;
              } else if (
                item.product_booked_info &&
                item.product_booked_info[1].is_exceed
              ) {
                const { limit, booked } = item.product_booked_info[1];
                Dialog.alert({
                  title: "超出限购",
                  message: `商品 “${productName}” 每人限购 ${limit} 个，您已购买 ${booked} 个${
                    limit - booked > 0
                      ? "，请确保下单数小于等于 " + (limit - booked)
                      : ""
                  }。`,
                });
                this.onSelect(product);
                return true;
              } else if (!item.sku_booked_info.has_stock) {
                Dialog.alert({
                  title: "暂无库存",
                  message: `商品 “${productName} ${skuName}” 没有足够的库存`,
                });
                this.onSelect(product);
                return true;
              } else if (item.sku_booked_info.is_exceed) {
                const { limit, booked } = item.sku_booked_info;
                Dialog.alert({
                  title: "超出限购",
                  message: `商品 “${productName} ${skuName}” 每人限购 ${limit} 个，您已购买 ${booked} 个${
                    limit - booked > 0
                      ? "，请确保下单数小于等于 " + (limit - booked)
                      : ""
                  }。`,
                });
                this.onSelect(product);
                return true;
              }
            } else {
              return true;
            }
          });
          if (!error) {
            this.onSetPendingOrder();
          }
        });
      }
    },
    onSetPendingOrder() {
      const products: CartProduct[] = [];
      const am_code = this.$route.query.user_coupon_id_am_code;
      this.list.map((item: Cart) => {
        if (this.selected.includes(item.sku.id)) {
          products.push({
            ...item,
            number: item.number,
            product_id: item.product.id,
            sku_id: item.sku.id,
          });
        }
      });
      dispatch
        .ordersSetPendingOrder({
          address_id: this.address.id as number,
          address: this.address,
          from_cart: true,
          products,
          user_coupon_id_am_code: am_code ? Number(am_code) : undefined,
        })
        .then(() => {
          this.selected = [];
          setTimeout(() => {
            this.$router.push(this.$paths.placeOrder);
          });
        });
    },
  },
  watch: {
    selected(selected) {
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          skus: selected.join(";"),
        },
      });
    },
  },
  mounted() {
    this.onRefresh();

    const { skus, address, user_coupon_id_am_code } = this.$route.query;
    if (skus) {
      this.selected = Array.from((skus as string).split(";"), (item) =>
        Number(item)
      );
    }
    if (address) {
      dispatch.addressesGet(address + "").then((res) => {
        this.address = res;
        this.addressLoading = false;
      });
    } else {
      dispatch.addressesGetList(this.addressQuery).then(() => {
        if (this.addressList[0]) {
          this.address = this.addressList[0];
        }
        this.addressLoading = false;
      });
    }
    if (user_coupon_id_am_code) {
      Dialog.alert({
        title: "下单提示",
        message:
          "必购码商品请尽快下单，取消后须从“个人中心”-“必购码”再次进入，直接进入购物车内无法完成下单。",
      });
    }
  },
});
